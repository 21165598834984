<template>
  <footer
    class="bg-body text-white container-fluid page-footer py-4 d-flex align-items-center border-top-dark-gray links-light text-left position-relative"
  >
    <section class="container">
      <div class="row">
        <div class="col-lg-6 d-flex align-items-center px-4">
          <div class="w-75-lg text-center m-auto">
            <div>
              <NewsletterForm></NewsletterForm>
            </div>
            <div class="mb-3 display-8">
              <span class="logo font-2 text-primary" href="/"
                >By Sound Web Services</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6 text-center">
          <div class="mb-1 text-muted">Links</div>
          <nav>
            <ul class="list-unstyled">
              <li>
                <router-link :to="{ name: 'privacyPolicy' }"
                  >Privacy Policy</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'termsAndConditions' }"
                  >Terms and Conditions</router-link
                >
              </li>
              <li>
                <a target="blank" href="https://stemplayer-js.com"
                  >Built using StemplayerJS</a
                >
              </li>
              <li><a href="mailto:hello@sound.ws">hello@sound.ws</a></li>
              <li>
                <a target="blank" href="https://www.firstcoders.co.uk"
                  >Website by First Coders</a
                >
              </li>
            </ul>
          </nav>

          <small class="text-white-50"
            >&copy; 2020 - 2024 Sound Web Services. All rights reserved.</small
          >
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import NewsletterForm from "./NewsletterForm.vue";

export default {
  name: "AppFooter",
  components: { NewsletterForm },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 150px;
}

p {
  color: #ddd;
}

a {
  text-decoration: none !important;
}

footer {
  padding-bottom: 150px !important;
}
</style>
